import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { AiFillTwitterCircle, AiFillInstagram } from 'react-icons/ai';
import { FaFacebook } from 'react-icons/fa';


import Modale from '../modal';
import Cookies from 'universal-cookie';
import './style.css'

export default function Footer() {
    const [modalShow, setModalShow] = useState(false);
    const cookie = new Cookies();

    const helpSupport = [
        {
            id: 1,
            title: 'Help Center',
        },
        {
            id: 2,
            title: 'Contact Us',
        },
        {
            id: 3,
            title: 'Privacy Policy',
        },
        {
            id: 4,
            title: 'Base Ball',
        },
        {
            id: 5,
            title: 'Basket Ball',
        },
        {
            id: 6,
            title: 'Foot Ball',
        },
        {
            id: 7,
            title: 'FAQ',
        },


    ];

    const links = [
        {
            id: 1,
            title: 'Bet Now'
        },
        {
            id: 2,
            title: 'Sports'
        },
        {
            id: 3,
            title: 'Upcoming Games'
        },
        {
            id: 4,
            title: 'Contact Us'
        },
        {
            id: 5,
            title: 'Golf'
        },
        {
            id: 6,
            title: 'Tennis'
        },
        {
            id: 7,
            title: 'Rugby'
        },


    ]

    return (
        <Container style={{ backgroundColor: '#3a3a3c', height: 'auto' }}>
            <Container>
                <Row className="linksContainer" sm={3} md={3} xs={2} >
                    <Col xs={12} style={{ paddingBottom: 16, textAlign: 'center' }}>
                        {/* <h4 style={{ marginBottom: 16 }}>Help & Support</h4> */}
                        {
                            helpSupport?.map((item) => (
                                <p key={item.id} style={{ opacity: '70%', cursor: 'pointer' }} onClick={() => setModalShow(true)}>{item.title}</p>
                            ))
                        }
                    </Col>
                    {/* <Col xs={12} style={{ textAlign: 'center' }}>
                        <h4>Links</h4>
                        {links?.map((item) => (
                            <p key={item.id} style={{ opacity: '70%', cursor: 'pointer' }} onClick={() => setModalShow(true)}>{item.title}</p>
                        ))
                        }
                    </Col> */}
                    <Col xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'column' }}>
                        <div className="logo2"></div>
                        <p style={{ fontSize: 14, opacity: '70%', textAlign: 'center' }}>
                            18+ Know When To Stop. Don’t Go Over The Top. Gamble Responsibly. Think! About Your Choices. Check Gambling Help on www.gamblinghelponline.org.au - www.gambleaware.nsw.gov.au
                        </p>
                    </Col>
                    <Col xs={12} style={{ textAlign: 'center' }}>
                        {/* <h4>Links</h4> */}

                        {links?.map((item) => (
                            <p key={item.id} style={{ opacity: '70%', cursor: 'pointer' }} onClick={() => setModalShow(true)}>{item.title}</p>
                        ))
                        }
                    </Col>
                </Row>
                {/*
                <div className='verticalLine'></div>
                <br></br>
                <Row style={{ color: 'white', textAlign: 'center' }}>
                    <Col>
                        <FaFacebook className="SocialMediaIcons " size={40} onClick={() => setModalShow(true)} />
                        <AiFillInstagram className="SocialMediaIcons " size={42} onClick={() => setModalShow(true)} />
                        <AiFillTwitterCircle className="SocialMediaIcons " size={43} onClick={() => setModalShow(true)} />
                    </Col>
                </Row>
                */}
                <br></br>

            </Container>
            <div className="copyRight" >
                <p style={{ margin: 0, paddingTop: 0 }}>Copyright © 2022 - All right reserved.</p>
            </div>
            <Modale
                show={modalShow}
                onHide={() => setModalShow(false)}
                signin={cookie.get('token') ? null : true}
            />
        </Container>
    )
}
