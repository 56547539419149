import React, { useState } from 'react'
import { Button, Container, FloatingLabel, Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { register } from '../../../api'
import '../style.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import ContactModal from '../../contactmodal';



export default function SignUp({ SupportModalShow, setSupportModalShow, Close }) {
    const [startDate, setStartDate] = useState(new Date());
    const [errorMessage, setErrorMessage] = useState()
    const [Message, setMessage] = useState()


    const [phoneValue, setPhoneValue] = useState()


    const validationSchema = Yup.object({
        firstname: Yup
            .string('Enter your first name')
            .required('First name is required'),
        lastname: Yup
            .string('Enter your last name')
            .required('Last name is required'),
        username: Yup
            .string('Enter your User name')
            .required('Username is required'),
        password: Yup
            .string('Enter your password')
            .required('Password is required'),
    })
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            username: '',
            password: '',
            birthdate: startDate,

        },
        validationSchema: validationSchema,
        onSubmit: () => {

            const data = {
                firstName: formik.values.firstname,
                lastName: formik.values.lastname,
                email: formik.values.email,
                phone: phoneValue,
                username: formik.values.username,
                password: formik.values.password,
                birthdate: startDate,

            }

            const func = register(data)

            func.then(async (response) => {
                if (response.success) {
                    setErrorMessage(response.data.message)
                } else {
                    setErrorMessage('User already token')
                }
            })

        }
    })
    return (
        <Container>
            {SupportModalShow ? null
                : errorMessage ?
                    <>
                        <div style={{ padding: 16, backgroundColor: '#ffeeff', marginBottom: 32, borderRadius: 24 }}>
                            <p style={{ color: errorMessage ? 'red' : 'green' }}>{errorMessage}</p>
                            <Button onClick={() => setSupportModalShow(true)} style={{ borderRadius: '100px', backgroundColor: '#55e6c1', color: 'black', border: 'none' }}>Contact Us</Button>
                        </div>
                    </>
                    : null
            }

            {
                SupportModalShow ?
                    <>
                        <ContactModal Close={Close} setSupportModalShow={setSupportModalShow} />
                    </>
                    :
                    <>
                        <Form onSubmit={formik.handleSubmit}>
                            <div style={{ display: 'flex' }}>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="First Name"
                                    className="mb-3"
                                    style={{ marginRight: 16 }}
                                >
                                    {/* <Form.Label><h5>First Name</h5></Form.Label> */}
                                    <Form.Control
                                        style={{ borderRadius: '100px' }}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.firstname}
                                        type="text"
                                        placeholder="Enter First Name"
                                        name="firstname" />
                                </FloatingLabel>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Last Name"
                                    className="mb-3"
                                >
                                    {/* <Form.Label><h5>Last Name</h5></Form.Label> */}
                                    <Form.Control
                                        style={{ borderRadius: '100px' }}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.lastname}
                                        type="text"
                                        placeholder="Enter Last Name"
                                        name="lastname" />
                                </FloatingLabel>
                            </div>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Email"
                                className="mb-3"
                            >
                                {/* <Form.Label><h5>Email Adress</h5></Form.Label> */}
                                <Form.Control
                                    style={{ borderRadius: '100px' }}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    type="email"
                                    placeholder="Enter Email Adress"
                                    name="email" />
                            </FloatingLabel>
                            <div>
                                <Form.Group className="mb-3" controlId="formBasicPhone" style={{ marginRight: 20 }}>
                                    {/* <Form.Label><h5>Phone Number</h5></Form.Label> */}
                                    <PhoneInput
                                        style={{ borderRadius: '100px', height: 58 }}
                                        className='phoneNumber'
                                        placeholder="Enter phone number"
                                        value={phoneValue}
                                        onChange={setPhoneValue}
                                        name="phone" />
                                </Form.Group>
                            </div>
                            <div>
                                <Form.Group className="mb-3" controlId="formBasicBirthDate">
                                    {/* <Form.Label><h5>Date Of Birth</h5></Form.Label> */}
                                    <DatePicker
                                        className='date'
                                        selected={startDate}
                                        name='birthdate'
                                        onChange={(date) => setStartDate(date)}
                                        value={startDate} />
                                </Form.Group>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Username"
                                    className="mb-3"
                                    style={{ marginRight: 16 }}
                                >
                                    {/* <Form.Label><h5>Username</h5></Form.Label> */}
                                    <Form.Control
                                        style={{ borderRadius: '100px' }}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.username}
                                        type="text"
                                        placeholder="Enter Username"
                                        name="username" />
                                </FloatingLabel>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Password"
                                    className="mb-3"
                                >
                                    {/* <Form.Label><h5>Password</h5></Form.Label> */}
                                    <Form.Control
                                        style={{ borderRadius: '100px' }}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.password}
                                        type="password"
                                        placeholder="Password"
                                        name="password" />
                                </FloatingLabel>
                            </div>
                            <Button type="submit" style={{ width: '100%', backgroundColor: '#55e6c1', border: 'none', padding: 16, borderRadius: '100px', color: 'black' }}>
                                Sign up
                            </Button>
                        </Form>
                    </>
            }

        </Container >
    )
}
