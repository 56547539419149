import React, { useEffect, useState } from 'react'
import { Button, Container, FloatingLabel, Form } from 'react-bootstrap';
import { useFormik } from 'formik';
// import * as Yup from 'yup';
import { login, getCusotmers } from '../../../api'
import Cookies from 'universal-cookie'
import LoginModal from '../../loginmodal';
import axios from 'axios';

export default function SignIn() {
    const [errorMessage, setErrorMessage] = useState()
    const [logInMessage, setLogInMessage] = useState('')
    const [modalShow, setModalShow] = useState(false);
    const [users, setUsers] = useState([]);
    const cookie = new Cookies()


    const getUsers = () => {
        getCusotmers().then(response => {
            setUsers(response.data)
        })
    }

    useEffect(async () => {
        getUsers()
    }, [])

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        onSubmit: () => {
            const username = formik.values.username
            const password = formik.values.password;
            const func = login(username, password)
            func.then(async (response) => {
                if (response.success) {
                    // setLogInMessage('Your account is suspended, Please contact customer support');
                    // alert(response.data.message);
                    // setModalShow(true);
                    cookie.set('token', response.token, { path: '/' })
                    cookie.set('firstname', response.data.firstName, { path: '/' })
                    cookie.set('username', response.data.username, { path: '/' })
                    cookie.set('id', response.data._id, { path: '/' })
                    cookie.set('balance', response.data.balance, { path: '/' })
                    cookie.set('currency', response.data.currency, { path: '/' })
                    window.location.reload()
                } else {
                    setErrorMessage(response.message)
                }
            })
        }
    })


    return (
        <Container >
            <p style={{ color: 'red' }}>
                {errorMessage}
            </p>
            <p style={{ color: 'red' }}>
                <a>{logInMessage}</a>
            </p>
            <Form onSubmit={formik.handleSubmit} >
                <FloatingLabel
                    controlId="floatingInput"
                    label="Username"
                    className="mb-3"
                >
                    {/* <Form.Label><h5>Username</h5></Form.Label> */}
                    <Form.Control
                        style={{ borderRadius: '100px' }}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.username}
                        type="text"
                        placeholder="Enter Username"
                        name="username" />
                </FloatingLabel>
                <FloatingLabel
                    controlId="floatingInput"
                    label="password"
                    className="mb-3"
                >
                    {/* <Form.Label><h5>Password</h5></Form.Label> */}
                    <Form.Control
                        style={{ borderRadius: '100px' }}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        type="password"
                        placeholder="Password"
                        name="password" />
                </FloatingLabel>
                <Button type="submit" style={{ width: '100%', backgroundColor: '#55e6c1', border: 'none', padding: 16, borderRadius: '100px', color: 'black' }}>
                    Sign in
                </Button>
            </Form>
            <LoginModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </Container>
    )
}
