import React, { useState } from 'react'
// import { Col, Container, Row } from 'react-bootstrap'
import './style.css'
import { sidebarData } from './sidebarData'
import Modale from '../modal';
import Cookies from 'universal-cookie';
import { Badge } from 'react-bootstrap';
import Marquee from "react-fast-marquee";


export default function SideBar() {
    const [modalShow, setModalShow] = useState(false);
    const cookie = new Cookies();

    return (
        <>
            {/* <div>
                <h4 className='betTypes'>
                    Bet Types
                </h4>
            </div> */}
            <Marquee gradient={false} speed={80} pauseOnHover={true}>
                <div className='types'>
                    {sidebarData?.map((data) =>
                        // <p key={data.id} onClick={() => setModalShow(true)} style={{ cursor: 'pointer' }}>{data.title}</p>
                        <div key={data.id} pill bg="info" className='typesBadge' onClick={() => setModalShow(true)}>
                            <p>{data.title}</p>
                        </div>
                    )}
                </div>
            </Marquee>
            {
                cookie.get('token') ?
                    <Modale
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                    // signin={true}
                    />
                    :
                    <Modale
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        signin={true}
                    />

            }
        </>

    )
}
