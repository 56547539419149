import { Container } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import NewsData from './newsData';
import useViewport from "../../hook/useViewport";
import './style.css'

export default function News({ news, setNews }) {
    const cookie = new Cookies();
    const { width } = useViewport();


    return (
        <>
            <Container>
                <h4 style={{ color: '#3a3a3c', fontWeight: 600 }}>What's new</h4>
                <div className={(width < 600 ? 'mobileNewsContainer' : 'newsContainer')} >
                    <NewsData signin={cookie.get('token') ? null : true} news={news} setNews={setNews} />
                </div>
                <br></br>
            </Container>
        </>
    )
}
