import { Container, Row, Col } from 'react-bootstrap';
import './App.css';
import Navigation from './components/navigation'
import Slideshow from './components/slideshow';
import Buttons from './components/button';
import SideBar from './components/sidebar';
// import { useState } from 'react';
import TableContainer from './components/table/tableContainer';
import Cookies from 'universal-cookie';
import Footer from './components/Footer';
import News from './components/news';
import Upcoming from './components/upcoming';
import CanceledGames from './components/canceledgames';
import LoginModal from './components/loginmodal';
import { useEffect, useState } from 'react';
import Modale from './components/modal';
import axios from 'axios';

function App() {
  const cookie = new Cookies();
  const [modalShow, setModalShow] = useState(true);
  const [news, setNews] = useState([]);

  useEffect(() => {
    axios.get('/api/news')
      .then(response => {
        setNews(response.data.items);
      })
      .catch(error => console.log(error));

  }, []);


  return (
    <div style={{ overflowX: 'hidden' }}>
      <Navigation />
      <Row>
        <Col>
          <Slideshow news={news} />
        </Col>
      </Row>
      {cookie.get('token') ?
        <Modale
          show={modalShow}
          onHide={() => setModalShow(false)}
        // signin={props.signin}
        />
        :
        null
      }
      <br></br>
      <Container>
        <Col lg={12} style={{ padding: 0 }}>
          <SideBar />
          <br></br>
          <Col>
            <Upcoming />
          </Col>
          <Col>
            <News news={news} setNews={setNews} />
          </Col>
          <Col>
            <CanceledGames />
          </Col>
          <Row>
            <TableContainer />
          </Row>
        </Col>
      </Container>
      <br></br>
      <Row>
        <Footer />
      </Row>

    </div >
  );
}

export default App;
