// import { Popover } from 'bootstrap';
import React, { useState } from 'react'
import { Nav, Navbar, Container, Dropdown } from 'react-bootstrap'
import './style.css';
import DropDown from '../dropdown';
import Buttons from '../button';
import Cookies from 'universal-cookie';
import Modale from '../modal';
import { navLinks } from './navLinks';
import SigninText from '../signinText';

export default function Navigation() {
    const cookie = new Cookies();
    const [modalShow, setModalShow] = useState(false);
    const [bg, setBg] = useState("transparent")

    // const token = true;
    return (
        <Container className="navContainer" fluid >
            <Container style={{ padding: 0 }}>
                <Navbar expand="md" style={{ padding: 0 }}>
                    <Navbar.Brand href="/">
                        <div className="logo"></div>
                    </Navbar.Brand>
                    <Navbar.Toggle className="hamburgerButton" aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav className="me-auto" ></Nav>

                        <Nav
                            className=" my-2 my-lg-0 linkContainer"
                        >
                            <Nav.Link className='navLink2' onClick={() => setModalShow(true)}>Home</Nav.Link>
                            <Nav.Link className='navLink2' onClick={() => setModalShow(true)}>Bet Now</Nav.Link>
                            <Dropdown className='sportDropdownContainer' >
                                <Dropdown.Toggle className="sportDropdown" id="dropdown-basic" style={{ backgroundColor: "transparent" }}>
                                    Sports
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='dropDownMenu'  >
                                    {navLinks?.map((data) =>
                                        <Dropdown.Item key={data.id} className={data.cName} onClick={() => setModalShow(true)}>{data.link}</Dropdown.Item>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Nav.Link className='upcomingGames' onClick={() => setModalShow(true)}>Upcoming games</Nav.Link>
                            <Nav.Link className='navLink22' onClick={() => setModalShow(true)} >contact us</Nav.Link>


                        </Nav>
                        {
                            cookie.get('token') ?
                                <>
                                    <DropDown />
                                    <Modale
                                        show={modalShow}
                                        onHide={() => setModalShow(false)}
                                    // signin={true}
                                    />
                                </>
                                :
                                <>
                                    {/* <div className="vl"></div> */}
                                    <SigninText signin={true} />
                                    <Buttons text='sign up' kind="orange" signin={false} />
                                    {/* <Buttons text='sign in' kind="none" signin={true} /> */}

                                    <Modale
                                        show={modalShow}
                                        onHide={() => setModalShow(false)}
                                        signin={true}
                                    />

                                </>

                        }
                        {/* {
                        cookie.get('token') ?
                            <>
                                <Nav
                                    className=" my-2 my-lg-0 linkContainer"
                                >
                                    <Nav.Link style={{ fontWeight: '700', color: 'white' }} onClick={() => setModalShow(true)}>home</Nav.Link>

                                    {navLinks?.map((data) =>
                                        <Nav.Link key={data.id} className={data.cName} onClick={() => setModalShow(true)}>{data.link}</Nav.Link>
                                    )}
                                </Nav>
                                <DropDown />
                                <Modale
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                // signin={true}
                                />

                            </>
                            :
                            <>
                                <Nav
                                    className=" my-2 my-lg-0 linkContainer"
                                >
                                    <Nav.Link style={{ fontWeight: '700', color: 'white' }} onClick={() => setModalShow(true)}>home</Nav.Link>
                                    {navLinks?.map((data) =>
                                        <Nav.Link key={data.id} className={data.cName} onClick={() => setModalShow(true)}>{data.link}</Nav.Link>
                                    )}

                                </Nav>
                                <Modale
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                    signin={true}
                                />

                            </>
                    } */}


                    </Navbar.Collapse>
                </Navbar >
            </Container>
        </Container >
    )
}
