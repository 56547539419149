import axios from 'axios';

const URL = "https://elitepunters.com";

export const login = async (username, password) => {
    const response = await axios.post('/auth/users/login', {
        username,
        password
    });
    if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
        return response.data;
    }
}

export const getCusotmers = async () => {
    const response = await axios.get('/users');
    if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
        return response.data
    }
}

export const getCustomer = async (id) => {
    const response = await axios.get('/users/' + id);
    if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
        return response.data
    }
}

export const register = async (data) => {
    const response = await axios.post('/auth/users/register', data);
    if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
        return response.data
    }
}

export const getNews = async () => {
    const response = await axios.get('https://elitepunters.com/api/news');
    if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
        // console.log(response.data)
        return response.data
    }
}
export const contactUs = async (email, message) => {
    const response = await axios.post('/api/contacts', {
        email,
        message
    });
    if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
        return response.data;
    }
}