import { Carousel, Container } from 'react-bootstrap'
import React from 'react'
import './style.css'
import useViewport from "../../hook/useViewport";



export default function Slideshow({ news }) {
    const { width } = useViewport();

    return (
        <Container style={{ padding: 0, marginTop: 8 }}  >
            <Carousel indicators={false} interval={2000} >

                <Carousel.Item style={{ width: '100%' }} >
                    <div
                        className=" carouselItem"
                        style={{
                            backgroundImage: `url(${require("../../assets/images/1.webp")})`,
                        }}
                    ></div>
                </Carousel.Item>
                <Carousel.Item style={{ width: '100%' }} >
                    <div
                        className=" carouselItem"
                        style={{
                            backgroundImage: `url(${require("../../assets/images/5.webp")})`,
                        }}
                    ></div>
                </Carousel.Item>
                <Carousel.Item style={{ width: '100%' }} >
                    <div
                        className=" carouselItem"
                        style={{
                            backgroundImage: `url(${require("../../assets/images/3.jpg")})`,
                        }}
                    ></div>
                </Carousel.Item>
                <Carousel.Item style={{ width: '100%' }} >
                    <div
                        className=" carouselItem"
                        style={{
                            backgroundImage: `url(${require("../../assets/images/4.webp")})`,
                        }}
                    ></div>
                </Carousel.Item>
                <Carousel.Item style={{ width: '100%' }} >
                    <div
                        className=" carouselItem"
                        style={{
                            backgroundImage: `url(${require("../../assets/images/image13.webp")})`,
                        }}
                    ></div>
                </Carousel.Item>
                <Carousel.Item style={{ width: '100%' }} >
                    <div
                        className=" carouselItem"
                        style={{
                            backgroundImage: `url(${require("../../assets/images/6.webp")})`,
                        }}
                    ></div>
                </Carousel.Item>

            </Carousel>
        </Container >

    )
}


