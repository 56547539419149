import { React, useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import Buttons from '../button/index.js';
import SignIn from './modalfields/signin.js';
import SignUp from './modalfields/signup.js'
import Cookies from 'universal-cookie';
import { getCustomer } from '../../api'
import ContactModal from '../contactmodal/index.js';
import './style.css'
import { FaUser, FaUserPlus } from 'react-icons/fa'
import { RiErrorWarningFill } from 'react-icons/ri'
export default function Modale(props) {
    const cookie = new Cookies()
    const [message, setMessage] = useState('')
    const [SupportModalShow, setSupportModalShow] = useState(false);


    useEffect(async () => {
        if (cookie.get('id')) {
            const user = getCustomer(cookie.get('id'))
            user.then(response => {
                setMessage(response.data.message)
            })
        }
    }, [])

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            // backdrop="static"
            dialogClassName="border-radius-2"
        >
            {
                props.signin === true ?
                    <>
                        <Modal.Header >
                            <Modal.Title id="contained-modal-title-vcenter">
                                {/* FaRegUserCircle */}
                                {/* <h3>Sign In</h3> */}
                                <FaUser style={{ color: 'white' }} size={45} />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <SignIn />
                        </Modal.Body>

                    </>
                    : props.signin === false ?
                        <>
                            <Modal.Header >
                                <Modal.Title id="contained-modal-title-vcenter">
                                    {
                                        SupportModalShow ?
                                            <RiErrorWarningFill style={{ color: 'white' }} size={45} />
                                            :
                                            <FaUserPlus style={{ color: 'white' }} size={45} />
                                    }
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <SignUp Close={props.onHide} setModalShow={props.setModalShow} SupportModalShow={SupportModalShow} setSupportModalShow={setSupportModalShow} />
                            </Modal.Body>

                        </>
                        :
                        <>
                            <Modal.Header>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    <RiErrorWarningFill style={{ color: 'white' }} size={45} />
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {
                                    SupportModalShow ?
                                        <ContactModal Close={props.onHide} setSupportModalShow={setSupportModalShow} />
                                        :
                                        <>
                                            <h5>
                                                {message}
                                            </h5>
                                            <br></br>
                                            <Button variant='primary' onClick={() => setSupportModalShow(true)} style={{ borderRadius: '100px', backgroundColor: '#55e6c1', border: 'none' }}>
                                                Contact Us
                                            </Button>
                                        </>
                                }
                            </Modal.Body>
                        </>
            }
        </Modal>
    )
}
