import React from 'react'
// import axios from 'axios';
import { useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import Modale from '../modal';
import Marquee from "react-fast-marquee";

// import Cookies from 'universal-cookie';

export default function NewsData({ signin, news, setNews }) {
    const [modalShow, setModalShow] = useState(false);

    return (
        <>
            <Marquee gradient={false} speed={100} pauseOnHover={true}>
                {news?.map((data, index) => (
                    <div key={index} className="newsDetailsContainer" >
                        <div style={{ padding: 10 }} onClick={() => setModalShow(true)}>
                            <Col>
                                <p className="newsTitle" style={{ fontSize: 16, marginBottom: 12, lineHeight: 1.3, height: '4rem' }}>{data.title}</p>
                            </Col>
                            <Col>
                                <img style={{ borderRadius: 12, width: 350, height: 230 }} src={data.enclosures[0].url} alt="news" />
                            </Col>
                            <Row>
                                <Col>
                                    <p style={{ fontSize: 12, marginTop: 8, lineHeight: 1.3, opacity: '50%', marginBottom: 0, width: 180 }}>{moment(data.published).format('dddd DD MMMM yyyy HH:MM')}</p>
                                </Col>
                                <Col>
                                    <p style={{ fontSize: 12, marginTop: 8, lineHeight: 1.3, opacity: '50%', color: '#1C53A5', marginBottom: 0 }}>{data.category}</p>
                                </Col>
                            </Row>
                        </div>

                    </div>
                ))}
            </Marquee>
            <Modale
                show={modalShow}
                onHide={() => setModalShow(false)}
                signin={signin}
            />
        </>
    )
}
